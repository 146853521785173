<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Settlement Accounts</h1>
      <div class=" flex justify-end items-end">
        <button
          class=" bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCsv"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center justify-between mt-10">
        <div class="w-full overflow-hidden lg:w-full xl:my-1 xl:px-1 xl:w-1/3">
          <div class="w-full py-5 flex flex-col bg-white rounded-lg ">
            <select
              class="inline-block ring-2 outline-none ring-gray-100 rounded-sm ml-2 text-sm py-4 px-3"
              name=""
              id=""
              v-model="searchParams"
              ><option value="">Search By</option>
              <option value="merchantID">Merchant ID</option>
              <option value="merchantName">Merchant name</option>
              <option value="accountNumber">Account number</option>
            </select>
          </div>
        </div>
        <div class="w-full overflow-hidden lg:w-full xl:my-1 xl:px-1 xl:w-1/3">
          <div class="w-full py-5 flex flex-col bg-white rounded-lg">
            <input
              type="text"
              class="inline-block ring-2 outline-none ring-gray-100 rounded-sm text-sm py-4 px-3"
              placeholder="Search by merchant ID, merchant Name, Account Number, Date"
              v-model="search"
            />
          </div>
        </div>
        <div class="w-full overflow-hidden lg:w-full xl:my-1 xl:px-1 xl:w-1/3">
          <button
            class="bg-blue-600 py-3 px-6 rounded-md text-white"
            @click="searchAccounts"
          >
            Get Results
          </button>
          <button
            class="bg-red-500 py-3 px-6 ml-2 rounded-md text-white"
            @click="resetSearchRecords"
          >
            Reset
          </button>
        </div>
      </div>
      <p v-if="errorMssg" class="text-xs text-red-600 m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-exclamation-triangle inline"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
          />
          <path
            d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
          />
        </svg>
        {{ errorMssg }}
      </p>
      <div class="my-4 flex bg-gray-50 p-5">
        <div class="block">
          <label class="text-xs block font-bold">Date (From)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white p-3 text-xs rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.startdatetime"
          />
        </div>
        <div class="block">
          <label class="text-xs block font-bold">Date (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white p-3 text-xs rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.enddatetime"
          />
        </div>
        <div class="block">
          <button
            class="inline-block text-xs
          bg-blue-900 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="handleDateFilter"
          >
            Search
          </button>
        </div>
        <div class="block">
          <button
            class="inline-block text-xs
          bg-red-600 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="handleDateFilterReset"
          >
            Reset
          </button>
        </div>
      </div>
      <!-- <div class="bg-blue-100 p-5 my-5 rounded">
        <p>
          Please wait some seconds after clicking on download button.
        </p>
      </div> -->
      <div class="mt-5" style="max-height: 600px; overflow: auto; ">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="7"
              style="background: #DEE6F2;"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merchant ID
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business Name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Bank Account Name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Bank Name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Bank Account
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date Created
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date Updated
            </th>
          </tr>
          <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
            <td>{{ account.settlementAccountBusiness.merchantId || "N/A" }}</td>
            <td>
              {{ account.settlementAccountBusiness.merchantName || "N/A" }}
            </td>
            <td>
              {{
                (account && account.businessName) || "N/A" | truncate(30, "...")
              }}
            </td>
            <td>
              {{ (account && account.settlementBank) || "N/A" }}
            </td>

            <td>
              {{ (account && account.accountNumber) || "N/A" }}
            </td>
            <td>
              {{
                (account && moment(account.createdAt)).format(
                  "DD MMM YYYY - h:mm:ss a"
                ) || "N/A"
              }}
            </td>
            <td>
              {{
                (account && moment(account.updatedAt)).format(
                  "DD MMM YYYY - h:mm:ss a"
                ) || "N/A"
              }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="isDisabled"
          class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { json2excel } from "js2excel";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      searchParams: "",
      accounts: null,
      download: false,
      currentPage: 1,
      isDisabled: false,
      dateData: null,
      errorMssg: "",
    };
  },
  computed: {
    ...mapGetters(["GET_SETTLEMENT_ACCOUNT_RES"]),
    filterAccounts() {
      return this.GET_SETTLEMENT_ACCOUNT_RES.data.sellementAccounts;
      // filter(
      //   (account) =>
      //     (account.bankAccounts.length > 0 &&
      //       account.bankAccounts[0].business_name
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase())) ||
      //     // (account.bankAccounts[0] &&
      //     //   account.bankAccounts[0].business_name
      //     //     .toLowerCase()
      //     //     .includes(this.search.toLowerCase())) ||
      //     account.merchantId.toLowerCase().includes(this.search.toLowerCase())
      // );
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    const d = new Date();

    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate() - 1))
        .toJSON()
        .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };
    this.fetchSettlementAccounts();
  },
  methods: {
    ...mapActions(["FETCH_SETTLEMENT_ACCOUNTS"]),
    async fetchSettlementAccounts() {
      try {
        this.isLoading = true;
        let payload = {
          startDate: this.dateData.startdatetime,
          endDate: this.dateData.enddatetime,
        };

        let response = await this.$store.dispatch(
          "FETCH_SETTLEMENT_ACCOUNTS",
          payload
        );
        this.accounts = response.data;
        this.isLoading = false;
      } catch (error) {}
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = {
        startDate: this.dateData.startdatetime,
        endDate: this.dateData.enddatetime,
      };

      let data = {
        page: currPage + 1,
        payload,
      };

      let res = await this.$store.dispatch("FETCH_PAGINATED_ACCOUNTS", data);

      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = {
        startDate: this.dateData.startdatetime,
        endDate: this.dateData.enddatetime,
      };

      let data = {
        page: currPage + 1,
        payload,
      };

      let res = await this.$store.dispatch("FETCH_PAGINATED_ACCOUNTS", data);
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
      this.isLoading = false;
    },
    async searchAccounts() {
      if (this.searchParams !== "" && this.search !== "") {
        this.isLoading = true;
        this.errorMssg = "";
        let payload = [this.searchParams, this.search.trim()];
        try {
          let response = await this.$store.dispatch(
            "SEARCH_SETTLEMENT_ACCOUNTS",
            payload
          );
          this.isLoading = false;
          if (response.status == true) {
            this.isLoading = false;
            this.fundsData = res.data;
            // console.log(res.data);
          } else {
            this.isLoading = false;
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {
          this.isLoading = false;
        }

        this.isLoading = false;
      } else {
        this.errorMssg = "The search param and search field is required.";
      }
    },

    resetSearchRecords() {
      if (this.searchParams !== "" && this.search !== "") {
        this.searchParams = "";
        this.search = "";
        this.fetchSettlementAccounts();
      }
    },

    handleDateFilterReset() {
      this.fetchSettlementAccounts();
    },
    async handleDateFilter() {
      if (this.dateData.startdatetime > this.dateData.enddatetime) {
        alert("Please choose a date lesser than end date");
      } else {
        this.isLoading = true;
        let payload = {
          startDate: this.dateData.startdatetime,
          endDate: this.dateData.enddatetime,
        };
        try {
          let response = await this.$store.dispatch(
            "FILTER_SETTLEMENT_ACCOUNTS",
            payload
          );
          if (response.status == true) {
            this.isLoading = false;
            this.fundsData = res.data;
            // console.log(res.data);
          } else {
            this.isLoading = false;
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    async downloadCsv() {
      this.isLoading = true;
      let payload = {
        startDate: this.dateData.startdatetime,
        endDate: this.dateData.enddatetime,
      };
      try {
        let response = await this.$store.dispatch(
          "DOWNLOAD_CSV_ACCOUNTS",
          payload
        );

        if (response.status) {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        } else {
          window.open(response.data, "_blank");
          this.isLoading = false;
          console.log(response);
        }
      } catch (error) {}
      // this.isLoading = false;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
